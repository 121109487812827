import React from 'react';
import {BrowserRouter as Router, Link, NavLink, Route, Switch, useParams} from "react-router-dom";

const Presentation = () => (
    <div id="presentation">
        <div className="page-header">
            <h3>
                Présentation
            </h3>
        </div>
        <div className="row">
            <div className="span5">
                <dl className="dl-horizontal">
                    <dt>
                        Adresse
                    </dt>
                    <dd>
                        <address>
                            Résidence Hippocampe<br/>
                            N°6 au 2ème étage<br/>
                            Boulevard Maillol<br/>
                            66755 St-Cyprien Plage
                        </address>
                    </dd>
                    <dt>
                        Téléphone
                    </dt>
                    <dd>
                        <address>
                            fixe: 04.68.54.54.15<br/>
                            portable: 06.05.17.61.18<br/>
                        </address>
                    </dd>
                    <dt>
                        E-mail
                    </dt>
                    <dd>
                        <img src="img/contact.png"/>
                    </dd>
                </dl>
            </div>
            <div className="span6">
                <Link to="/photos">
                    <img className="img-rounded img-polaroid" src="img/vue_exterieure.jpg"/>
                </Link>
            </div>
        </div>
    </div>
)

const Appartement = () => (
    <div id="appartement">
        <div className="page-header">
            <h3>
                L'appartement
            </h3>

        </div>
        <h4>Loue studio type T1 entièrement meublé et équipé dans résidence sécurisée avec digicode, vue mer,
            tout confort, plein centre à saint cyprien plage. (docteur, pharmacie, commerces , supermarché,
            restaurants, boulangerie sur place).</h4>
        <div className="row">
            <div className="span6">

                <ul>
                    <li>50 mètres de la plage, 200 mètres du port, promenade piétonnière et cyclable longeant la
                        mer, 1
                        km du golf 27 trous, thalasso à 3 km
                    </li>
                    <li>Marchés au pied de l'immeuble le mardi et le vendredi</li>
                    <li>Climatisé et ensoleillé plein sud</li>
                    <li>Place de parking avec barrière, possibilité de ne pas prendre la voiture et de tout
                        faire à
                        pied...
                    </li>
                    <li>2 ième étage</li>
                    <li>Cuisine : évier, lave vaisselle, four micro ondes, plaques vitro céramiques,
                        réfrigérateur
                        congélateur, machine à café, grille pain , tous les ustensiles
                    </li>
                    <li>Salle de bain, toilettes, lavabo, machine à laver le linge</li>
                    <li>Coin nuit avec lit matrimonial, armoire, salon avec télévision écran plat, canapé
                        convertible,
                        table et chaises, étendoir, table et fer à repasser
                    </li>
                    <li>Balcon vue mer avec table et chaises</li>
                    <li>Location juillet, août, septembre</li>
                    <li>30 minutes de Collioure</li>
                    <li>1 heure de l'Espagne</li>
                    <li>1 heure 30 des Pyrénées</li>
                    <li>Bus à 1 euros pour la visite dans le département à 50 mètres</li>
                </ul>
            </div>
            <div className="span6"><img
                src="/img/appartement/vue.jpg"/></div>
        </div>

    </div>
)

const Tarifs = () => (
    <div id="tarifs">
        <div className="page-header">
            <h3>
                Tarifs
            </h3>
        </div>

        <div className="row">

            <div className="span6">
                <strong>MOIS DE JUILLET :</strong><br/>
                Du 02/07/16 au 09/07/16 : 400 € TOUT COMPRIS<br/>
                Du 09/07/16 au 16/07/16 : 550 €<br/>
                Du 16/07/16 au 23/07/16 : 550 €<br/>
                Du 23/07/16 au 30/07/16 : 550€
            </div>
            <div className="span6">
                <strong>MOIS D'AOUT :</strong><br/>
                Du 30/07/16 au 06/08/16 : 550 €<br/>
                Du 06/08/16 au 13/08/16 : 600 €<br/>
                Du 13/08/16 au 20/08/16 : 600 €<br/>
                Du 20/08/16 au 27/08/16 : 450 €
            </div>

        </div>
        <br/>
        <div style={{textAlign: 'center'}}>
            <p>Tarif dégressif pour 2 semaines<br/>
                N'hésitez pas à me contacter pour plus de renseignements par mail ou par téléphone.</p>
        </div>
    </div>

)

const Photos = () => {
    let {cat} = useParams();
    console.log({cat})
    return (
        <div id="photos">
            <div className="page-header">
                <h3>
                    Photos
                </h3>
            </div>
            <ul className="thumbnails">
                <li className="span6">
                    <div href="#" className="thumbnail">
                        <img src="/img/appartement/vue.jpg"
                             data-src="img/appartement/vue.jpg" alt=""/>
                        <p>
                            Vue depuis l'appartement
                        </p>
                    </div>
                </li>
                <li className="span6">
                    <div href="#" className="thumbnail">
                        <img
                            src="/img/appartement/vue%20mer%20palmier2.jpg"
                            data-src="img/appartement/vue%20mer%20palmier2.jpg" alt=""/>
                        <p>
                            Vue depuis l'appartement
                        </p>
                    </div>
                </li>
                <li className="span6">
                    <div href="#" className="thumbnail">
                        <img src="/img/appartement/coin%20nuit.jpg"
                             data-src="img/appartement/coin%20nuit.jpg" alt=""/>
                        <p>
                            Coin nuit
                        </p>
                    </div>
                </li>
                <li className="span6">
                    <div href="#" className="thumbnail">
                        <img src="/img/appartement/salon.jpg"
                             data-src="img/appartement/salon.jpg" alt=""/>
                        <p>
                            Salon
                        </p>
                    </div>
                </li>
                <li className="span6">
                    <div href="#" className="thumbnail">
                        <img src="/img/appartement/salonbalcon.jpg"
                             data-src="img/appartement/salonbalcon.jpg" alt=""/>
                        <p>
                            Salon / Balcon
                        </p>
                    </div>
                </li>
                <li className="span6">
                    <div href="#" className="thumbnail">
                        <img src="/img/appartement/cuisine2.jpg"
                             data-src="img/appartement/cuisine2.jpg" alt=""/>
                        <p>
                            La cuisine
                        </p>
                    </div>
                </li>
                <li className="span6">
                    <div href="#" className="thumbnail">
                        <img src="/img/appartement/balcon.jpg"
                             data-src="img/appartement/balcon.jpg" alt=""/>
                        <p>
                            Le balcon
                        </p>
                    </div>
                </li>
                <li className="span6">
                    <div href="#" className="thumbnail">
                        <img src="/img/vue_exterieure.jpg"
                             data-src="img/vue_exterieure.jpg" alt=""/>
                        <p>
                            La résidence
                        </p>
                    </div>
                </li>
            </ul>
        </div>


    );
}


const NavLi = ({to, children}) => {
    return (
        <NavLink exact to={to} component={({className, navigate, ...otherProps}) => {
            return <li className={className}><a {...otherProps}/></li>;
        }}>{children}</NavLink>
    )
}


function App() {
    return (
        <Router>
            <div className="container">
                <h1>
                    <img className="hide" src="img/logo.gif" alt="loca-sud" title="loca-sud"/> loca-sud.com <small>Studio
                    F1 à louer sur St-Cyprien</small>
                </h1>
                <div className="navbar">
                    <div className="navbar-inner">
                        <ul className="nav">
                            <NavLi to="/">Présentation</NavLi>
                            <NavLi to="/appart">L'appartement</NavLi>
                            <NavLi to="/photos">Photos</NavLi>
                            <NavLi to="/tarifs">Tarifs</NavLi>
                        </ul>
                    </div>
                </div>
                <div className="tab-content">

                    <Switch>
                        <Route path="/appart">
                            <Appartement/>
                        </Route>
                        <Route path="/photos">
                            <Photos/>
                        </Route>
                        <Route path="/tarifs">
                            <Tarifs/>
                        </Route>
                        <Route path="/">
                            <Presentation/>
                        </Route>
                    </Switch>

                </div>
            </div>
        </Router>
    )
}

export default App;
